export default {
  inset: {
    default: 'inset 0 0 0 2px #171717',
    _dark: 'inset 0 0 0 2px #ffffff',
  },
  'inset-on-accent': {
    default: 'inset 0 0 0 2px #ffffff',
    _dark: 'inset 0 0 0 2px #171717',
  },
  none: {
    default: 'none',
    _dark: 'none',
  },
  xs: {
    default: '0px 0px 1px rgba(48, 49, 51, 0.05), 0px 1px 3px rgba(48, 49, 51, 0.1)',
    _dark: '0px 0px 1px #0D0D0D, 0px 1px 3px rgba(13, 13, 13, 0.9)',
  },
  sm: {
    default: '0px 0px 1px rgba(48, 49, 51, 0.05), 0px 2px 4px rgba(48, 49, 51, 0.1)',
    _dark: '0px 0px 1px #0D0D0D, 0px 2px 4px rgba(13, 13, 13, 0.9)',
  },
  md: {
    default: '0px 0px 1px rgba(48, 49, 51, 0.05), 0px 4px 8px rgba(48, 49, 51, 0.1)',
    _dark: '0px 0px 1px #0D0D0D, 0px 4px 8px rgba(13, 13, 13, 0.9)',
  },
  lg: {
    default: '0px 0px 1px rgba(48, 49, 51, 0.05), 0px 8px 16px rgba(48, 49, 51, 0.1)',
    _dark: '0px 0px 1px #0D0D0D, 0px 8px 16px rgba(13, 13, 13, 0.9)',
  },
  xl: {
    default: '0px 0px 1px rgba(48, 49, 51, 0.05), 0px 16px 24px rgba(48, 49, 51, 0.1)',
    _dark: '0px 0px 1px #0D0D0D, 0px 16px 24px rgba(13, 13, 13, 0.9)',
  },
}
