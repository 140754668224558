import { HTMLChakraProps, chakra } from "@chakra-ui/react";
import { useName } from "../contexts/NameContext";

export const Logo = (props: HTMLChakraProps<"svg">) => {
  // Call useName to get the name and updateName (if needed)
  const { name } = useName();

  // Optionally, split "FTX.Finance" if you want "FTX" and "Finance" as separate parts
  // Example: const [brand, domain] = name.split('.');

  return (
    <chakra.svg
      height="50" // Adjust height as needed
      width="200" // Adjust width as needed
      viewBox="0 0 200 50" // Adjust viewBox based on the actual size and layout you need
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <style>
        {`
          .ftx-text { font: bold 21px sans-serif; fill: black; }
          .finance-text { font: bold 23px sans-serif; fill: white; text-anchor: middle; }
          .finance-bg { fill: black; }
        `}
      </style>
      {/* Use the name directly in the text element. Adjust positioning if needed. */}
      <text x="-1" y="35" className="ftx-text">
        {name}
      </text>
      <rect
        x="50"
        y="10"
        width="95"
        height="30"
        rx="5"
        className="finance-bg"
      />{" "}
      {/* Background for "Finance", adjust width as necessary */}
      {/* Adjust the x attribute to be the center of the rectangle (50 + half of the rectangle's width) */}
      <text x="97" y="35" className="finance-text">
        Finance
      </text>{" "}
      {/* Center "Finance" in the rectangle */}
    </chakra.svg>
  );
};
