// src/pages/Opportunity.tsx
import React, { useEffect, useState } from "react";
import {
    Box,
    Container,
    Heading,
    Text,
    Button,
    Image,
    AspectRatio,
    SimpleGrid,
    Stack,
    Card,
    CardBody,
    CardFooter,
    Spacer,
    HStack,
    TableContainer,
    Table,
    Thead,
    Tr,
    Tbody,
    Th,
    Tfoot,
    Td,
} from "@chakra-ui/react";
// import { useRouter } from 'next-routes';
import { useParams } from 'react-router-dom';

// A page that takes an opporutnityID from the url and then fetches the opportunity from the API and displays it

import { StatsWithNumbers } from "../../components/StatsWithNumbers";
import { StoriesWithAccordion } from "../../components/StoriesWithAccordion";
import { Opportunities } from "../../components/Opportunities";
import { NavBarLogic } from "../../components/NavBarLogic";
import { Footer } from "../../components/Footer";
import axios from "axios";
import { OpportunityUpdates } from "../../components/OpportunityUpdates";





export const Opportunity = () => {
    let { opportunityId } = useParams();
    console.log(opportunityId);
    const [opportunities, setOpportunities] = useState([]);

    //todo: get the opportunity by id and then display it below. For now, just fetch all the opportunities and filter if there is no end point for fetching by id

    useEffect(() => {
        const fetchOpportunities = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API}/opportunities`
                );
                if (!response.data) throw new Error("Data could not be fetched!");
                const data = await response.data;
                console.log("Opportunities fetched successfully", data);
                setOpportunities(data);
            } catch (error) {
                console.error("Failed to fetch opportunities:", error);
            }
        };

        fetchOpportunities();
    }, []);





    return (
        <>
            <NavBarLogic></NavBarLogic>

            <Box as="section">
                <Container py={{ base: "16", md: "24" }}>
                    <Stack spacing={{ base: "8", md: "10" }}>
                        <Stack spacing={{ base: "4", md: "5" }} align="center">
                            <Heading size={{ base: "sm", md: "md" }}>
                                Sam and her three Children
                            </Heading>
                            <Text
                                color="fg.muted"
                                maxW="2xl"
                                textAlign="center"
                                fontSize="xl"
                            >
                                Here is one of our opportunities and the updates on it
                            </Text>
                        </Stack>
                    </Stack>





                    <Card
                        direction={{ base: 'column', sm: 'row' }}
                        overflow='hidden'
                        variant='outline'
                    >
                        <Image
                            objectFit='cover'
                            maxW={{ base: '100%', sm: '200px' }}
                            src='/assets/sam.webp'
                            alt='Caffe Latte'
                        />

                        <Stack>
                            <CardBody>

                                <Heading size='md'>Really a Dr?</Heading>

                                <Text py='2'>
                                    Sam has 3 children under the age of 12 and is on a modest income. Her ex, an questionable Medical Doctor with own practice refuses to pay child support. Sam is seeking funding to resolve her matter and move on.
                                </Text>
                                <Text py='2'>todo: put the rest of the opportunity details here</Text>
                                <Heading size='md'>Case Summary</Heading>
                                <HStack >
                                    <Text textStyle="lg" fontWeight="medium">
                                        Budget:
                                    </Text>
                                    <Text >45K</Text>


                                </HStack>
                                <HStack >
                                    <Text textStyle="lg" fontWeight="medium">
                                        Spent So Far:
                                    </Text>
                                    <Text >13K (28%)</Text>




                                </HStack>

                                <br />
                                <TableContainer>
                                    <Table size='sm'>
                                        <Thead>
                                            <Tr>
                                                <Th>Date</Th>
                                                <Th>Invoice Number</Th>
                                                <Th >Amount</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            <Tr>
                                                <Td>12th Apri 2024</Td>
                                                <Td>No. 001</Td>
                                                <Td >$4,000</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>30th April 2024</Td>
                                                <Td>No. 002</Td>
                                                <Td >$5,000</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>11th May 2024</Td>
                                                <Td>No. 003</Td>
                                                <Td >$4,000</Td>
                                            </Tr>
                                        </Tbody>
                                        <Tfoot>
                                            <Tr>
                                                <Th>Date</Th>
                                                <Th>Invoice Number</Th>
                                                <Th >Amount</Th>
                                            </Tr>
                                        </Tfoot>
                                    </Table>
                                </TableContainer>



                                <Spacer m={10} />

                                {opportunityId && <OpportunityUpdates opportunityId={opportunityId} />}
                            </CardBody>

                            <CardFooter>
                                {/* <Button variant='solid' colorScheme='blue'>
                                    Buy Latte
                                </Button> */}
                            </CardFooter>
                        </Stack>
                    </Card>
                </Container>
            </Box>

            <Box id="footer">
                <Footer />
            </Box>
        </>
    );
};
