export const steps = [
    {
      title: 'Step 1',
      description: 'Seeking Capital',
    },
    {
      title: 'Step 2',
      description: 'Case Finalised',
    },
    {
      title: 'Step 3',
      description: 'Law Firm Engaged',
    },
    {
      title: 'Step 4',
      description: 'Final Settlement',
    },
  ]