import React, { useEffect, useState } from 'react';
import axios from 'axios'; // Make sure to import axios
import { Box, Button, Container, IconButton, Stack, StackDivider, Text } from '@chakra-ui/react';
import { FiEdit2, FiTrash2 } from 'react-icons/fi';
import { Step } from './Step';
import { useStep } from './useStep';
import { steps } from './../data/step_data';


// Define an interface for the update object
interface Update {
  title: string;
  description: string;

}


// OpportunityUpdates.tsx

interface OpportunityUpdatesProps {
  opportunityId: string;
}


export const OpportunityUpdates = ({ opportunityId }: OpportunityUpdatesProps) => {
  const [updates, setUpdates] = useState<any>([]);
  const [currentStep, { setStep }] = useStep({ maxStep: steps.length, initialStep: 2 })

  console.log('opportunityID', opportunityId);

  useEffect(() => {
    console.log('Fetching updates for opportunity ID:', opportunityId);
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/opportunities/${opportunityId}/updates`);
        setUpdates(response.data); // Assuming response.data is the array of updates
        console.log('Updates fetched successfully', response.data);
      } catch (error) {
        console.error('Error fetching updates for opportunity ID:', opportunityId, error);
      }
    };

    if (opportunityId) {
      fetchData();
    }
  }, [opportunityId]);


  // If there are no updates, you can decide to return `null` or some placeholder here
  if (updates.length === 0) {
    return null; // Or, you could return some placeholder indicating "No updates"
  }

  return (
    <Box as="section" py={{ base: '1', md: '1' }}>

      <Box bg="bg.surface" boxShadow="sm" borderRadius="lg" p={{ base: '4', md: '4' }}>
        <Stack spacing="5" divider={<StackDivider />}>
          <Stack justify="space-between" direction={{ base: 'column', sm: 'row' }} spacing="5">
            <Stack spacing="1">
              <Text textStyle="lg" fontWeight="medium">
                Opportunity Updates
              </Text>
              <Text textStyle="sm" color="fg.muted">
                Here are the latest updates on the opportunity.
              </Text>
            </Stack>

          </Stack>
          {updates.length > 0 && updates.map((update: Update, index: any) => (
            <Stack key={index} justify="space-between" direction="row" spacing="4">
              <Stack spacing="0.5" fontSize="sm">
                <Text color="fg.emphasized" fontWeight="medium">
                  {update.title}
                </Text>
                <Text color="fg.muted">{update.description}</Text>
              </Stack>

            </Stack>
          ))}
        </Stack>
      </Box>

      <Box bg="bg.surface">
        <Container py={{ base: '8', md: '16' }}>
          <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
            {steps.map((step, id) => (
              <Step
                key={id}
                cursor="pointer"
                onClick={() => setStep(id)}
                title={step.title}
                description={step.description}
                isActive={currentStep === id}
                isCompleted={currentStep > id}
              />
            ))}
          </Stack>
        </Container>
      </Box>

    </Box>
  );
};
