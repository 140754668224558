/**
 * disable rounded corners for all kinds of elements
 */
export default {
  none: '0',
  sm: 0,
  base: 0,
  md: 0,
  lg: 0,
  xl: 0,
  '2xl': 0,
  '3xl': 0,
  full: '9999px',
}
