import React from "react";
import {
  Box,
  Container,
  Button,
  ButtonGroup,
  HStack,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import { Link as ChakraLink } from "@chakra-ui/react";
import { Logo } from "../components/Logo";
import { ToggleButton } from "../components/ToggleButton";
import { MobileDrawer } from "../components/MobileNavbar";
import { useNavigate } from "react-router-dom";

export const NavBarLogic = () => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const mobileNavbar = useDisclosure();

  const navigate = useNavigate();
  const onClose = mobileNavbar.onClose; // Assuming onClose is defined to close the drawer

  const handleLinkClick = (path: string) => {
    onClose(); // Close the drawer if open
    navigate(path); // Navigate to the specified path
  };

  return (
    <>
      <div>
        <Box as="section" minH="170" id="logo">
          <Box
            borderBottomWidth="1px"
            bg="bg.surface"
            position="relative"
            zIndex="tooltip"
          >
            <Container py="4">
              <HStack justify="space-between">
                <ChakraLink href="/">
                  <Logo />
                </ChakraLink>

                {isDesktop ? (
                  <HStack spacing="8">
                    <ButtonGroup
                      size="lg"
                      variant="text"
                      colorScheme="gray"
                      spacing="8"
                    >
                      {["Opportunities To Fund", "Learn More", "Example"].map((item) => {
                        let path = "/";
                        if (item === "Opportunities To Fund") path = "/opportunities";
                        if (item === "Learn More") path = "/learnmore";
                        if (item === "Example") path = "/example";

                        return (
                          <ChakraLink
                            key={item}
                            onClick={(e) => {
                              e.preventDefault();
                              handleLinkClick(path);
                            }}
                            style={{ width: "100%", display: "inline-flex" }}
                          >
                            <Button
                              size="lg"
                              variant="text"
                              colorScheme="gray"
                              width="100%"
                            >
                              {item}
                            </Button>
                          </ChakraLink>
                        );
                      })}
                    </ButtonGroup>
                    <ChakraLink href="/apply">
                      <Button>Apply Now</Button>
                    </ChakraLink>
                  </HStack>
                ) : (
                  <>
                    <ToggleButton
                      onClick={mobileNavbar.onToggle}
                      isOpen={mobileNavbar.isOpen}
                      aria-label="Open Menu"
                    />
                    <MobileDrawer
                      isOpen={mobileNavbar.isOpen}
                      onClose={mobileNavbar.onClose}
                    />
                  </>
                )}
              </HStack>
            </Container>
          </Box>
        </Box>
      </div>
    </>
  );
};
