export const stats = [
  {
    label: "Funds Raised",
    description: "Funds raised to support families in need",
    value: "$140K",
    cta: "Learn more about our impact",
  },
  {
    label: "Families Helped",
    description: "Families supported through our platform",
    value: "1 and counting",
    cta: "Read their stories",
  },
  {
    label: "Legal Partners",
    description: "Collaborations with legal firms and advisors",
    value: "2 Partners",
    cta: "See our partners",
  },
  {
    label: "Client Satisfaction",
    description: "Feedback from those we’ve supported",
    value: "4.9/5 Rating",
    cta: "Why choose us",
  },
];
