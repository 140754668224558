import { ColorModeScript } from "@chakra-ui/react";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { App } from "./App";
import { NameProvider } from "./contexts/NameContext";
import { AuthProvider } from "./contexts/AuthContext"; // Import AuthProvider

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(container);

root.render(
  <React.StrictMode>
    <NameProvider>
      <AuthProvider>
        {" "}
        {/* Wrap the entire app with AuthProvider */}
        <ColorModeScript />
        <App />
      </AuthProvider>
    </NameProvider>
  </React.StrictMode>
);
