import React from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Divider,
  HStack,
  IconButton,
  Input,
  SimpleGrid,
  Stack,
  Text,
  VStack,
  Link as ChakraLink,
} from "@chakra-ui/react";
import { FaGithub, FaLinkedin, FaTwitter } from "react-icons/fa";
import { Logo } from "./Logo";
import { links } from "../data/footer_links";
import { Badge } from "@chakra-ui/react";
import { useName } from "../contexts/NameContext";

export const Footer = () => {
  const { name } = useName();

  return (
    <>
      <Box bg="bg.surface">
        <Container as="footer" role="contentinfo">
          <Stack
            spacing={{ base: "12", md: "8" }}
            direction={{ base: "column-reverse", lg: "row" }}
            py={{ base: "12", md: "16" }}
            justify="space-between"
          >
            {/* <SimpleGrid columns={{ base: 2, md: 4 }} gap="8" width={{ base: 'full', lg: 'auto' }}>
                    {links.map((group, idx) => (
                        <Stack key={idx} spacing="4" minW={{ lg: '40' }}>
                            <Text fontSize="sm" fontWeight="semibold" color="fg.subtle">
                                {group.title}
                            </Text>
                            <Stack spacing="3" shouldWrapChildren>
                                {group.links.map((link, idx) => (
                                    <Button key={idx} as="a" variant="text" colorScheme="gray" href={link.href}>
                                        {link.label}
                                    </Button>
                                ))}
                            </Stack>
                        </Stack>
                    ))}
                </SimpleGrid> */}
            {/* <Stack spacing="4">
                    <Text fontSize="sm" fontWeight="semibold" color="fg.subtle">
                        Stay up to date
                    </Text>
                    <Stack spacing="4" direction={{ base: 'column', sm: 'row' }} maxW={{ lg: '360px' }}>
                        <Input placeholder="Enter your email" type="email" required />
                        <Button type="submit" flexShrink={0}>
                            Subscribe
                        </Button>
                    </Stack>
                </Stack> */}
          </Stack>
          <Divider />
          <Stack
            pb="12"
            pt="8"
            justify="space-between"
            direction={{ base: "column", md: "row" }}
            align={{ base: "start", md: "center" }}
          >
            <HStack
              justify={{ base: "space-between", sm: "start" }}
              width={{ base: "full", sm: "auto" }}
              spacing="8"
            >
              <ChakraLink href="/">
                <Logo />
              </ChakraLink>
              <ButtonGroup variant="tertiary">
                <IconButton
                  as="a"
                  href="https://github.com/ftxfinance"
                  aria-label="LinkedIn"
                  icon={<FaLinkedin />}
                />
                <IconButton
                  as="a"
                  href="https://github.com/ftxfinance"
                  aria-label="GitHub"
                  icon={<FaGithub />}
                />
                <IconButton
                  as="a"
                  href="https://github.com/ftxfinance"
                  aria-label="Twitter"
                  icon={<FaTwitter />}
                />
              </ButtonGroup>
            </HStack>
            <VStack>
              <Text fontSize="sm" color="fg.subtle">
                &copy; {new Date().getFullYear()} {name}Finance Pty. Ltd. All
                rights reserved.
              </Text>
              <Badge colorScheme="green">
                Concept Website Before Incorporation
              </Badge>
            </VStack>
          </Stack>
        </Container>
      </Box>
    </>
  );
};
