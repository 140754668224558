// src/pages/Home.tsx
import React from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  Button,
  Image,
  AspectRatio,
  SimpleGrid,
  Stack,
  Link as ChakraLink,
  Center,
} from "@chakra-ui/react";

import { StatsWithNumbers } from "../components/StatsWithNumbers";
import { StoriesWithAccordion } from "../components/StoriesWithAccordion";
import { Opportunities } from "../components/Opportunities";
import { NavBarLogic } from "../components/NavBarLogic";
import { Footer } from "../components/Footer";

export const HomePage = () => {
  return (
    <>
      <NavBarLogic></NavBarLogic>

      <Box id="hero">
        <Container>
          <SimpleGrid columns={{ base: 1, md: 2 }} gap={16}>
            <Stack spacing={{ base: "8", md: "12" }} justifyContent="center">
              <Stack spacing={{ base: "4", md: "6" }}>
                <Heading size={{ base: "md", md: "xl" }}>
                  Seek capital from the crowd to assist with your legal matters at rates you set.
                </Heading>
                <Text fontSize={{ base: "lg", md: "xl" }} color="fg.muted">
                  CMD finance is a platform that allows you to raise funds for your legal matters, by outsourcing the funding to the crowd.  The crowd will then be able to invest in your legal matter and earn a return on their investment.
                </Text>
              </Stack>
              <Stack direction={{ base: "column", md: "row" }} spacing="3">
                <ChakraLink href="/apply">
                  <Button size={{ base: "lg", md: "xl" }}>Apply now</Button>
                </ChakraLink>
                <ChakraLink href="/learnmore">
                  <Button variant="secondary" size={{ base: "lg", md: "xl" }}>
                    Learn more
                  </Button>
                </ChakraLink>
              </Stack>
            </Stack>
            <AspectRatio ratio={1}>
              <Image
                objectFit="cover"
                src="https://tinyurl.com/yeyjvptc"
                alt="Client testimonial"
              />
            </AspectRatio>
          </SimpleGrid>
        </Container>
      </Box>
      <br ></br>
      <br ></br>
      <br ></br>


      <Box id="stories">
        <StoriesWithAccordion />
      </Box>
      <Container py={{ base: "16", md: "24" }}>
        <Box>
          <Center>

            <video controls width="100%">
              <source src="/assets/concept_expliner.mp4" type="video/mp4" />
            </video>
          </Center>
        </Box>
      </Container>
      <Container py={{ base: "16", md: "24" }}>
        <Stack spacing={{ base: "4", md: "6" }}>
          <Stack spacing="4">


          </Stack>
          <Text textStyle={{ base: "lg", md: "xl" }} color="fg.muted">
            Clients who are in need of legal assistance can apply for funding on
            the platform. The crowd will then be able to invest in the clients
            based on the investors risk appetite and legal considerations. The
            client will then be able to pay back the investors based on the
            agreed terms upon settlement.
          </Text>
        </Stack>
        <SimpleGrid columns={{ base: 1, md: 2 }} gap={12}>

          {/* <Accordion defaultIndex={0}>
          {stories.map((story, id) => (
            <StoryCard key={id} {...story} />
          ))}
        </Accordion> */}
        </SimpleGrid>
      </Container>


      <Box id="stats">
        <StatsWithNumbers />
      </Box>

      <Box id="opportunities">
        <Opportunities />
      </Box>

      <Box id="footer">
        <Footer />
      </Box>
    </>
  );
};
