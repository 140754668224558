import {
  Accordion,
  Center,
  Container,
  Heading,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import { StoryCard } from "./StoryCard";
import axios from "axios";
import { useEffect, useState } from "react";
import { Story } from "../types";
import { useName } from "../contexts/NameContext";

export const StoriesWithAccordion = () => {
  const [stories, setStories] = useState<Story[]>([]);
  const { name } = useName();

  useEffect(() => {
    const fetchStories = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/stories`
        );
        if (!response.status) throw new Error("Data could not be fetched!");
        const data = await response.data;
        setStories(data);
      } catch (error) {
        console.error("Failed to fetch opportunities:", error);
      }
    };

    fetchStories();
  }, []);

  return (
    <Container py={{ base: "16", md: "24" }}>
      <Stack spacing={{ base: "4", md: "6" }}>
        <Stack spacing="4">
          {/* <Text
              fontWeight="semibold"
              color="accent"
              textStyle={{ base: "sm", md: "md" }}
            >
              Transforming Lives
            </Text> */}
          <Center>
            <Heading as="h1" size={{ base: "md", md: "lg" }}>
              How it works
            </Heading>


          </Center>

        </Stack>
      
      </Stack>
      <SimpleGrid columns={{ base: 1, md: 2 }} gap={12}>

        {/* <Accordion defaultIndex={0}>
          {stories.map((story, id) => (
            <StoryCard key={id} {...story} />
          ))}
        </Accordion> */}
      </SimpleGrid>
    </Container>
  );
};
