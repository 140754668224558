import React from "react";
import { Container, Heading, Stack, Text } from "@chakra-ui/react";
import { Stat } from "./Stat";
import { stats } from "../data/stats";
import { useName } from "../contexts/NameContext";

export const StatsWithNumbers = () => {
  const { name } = useName();

  return (
    <Container py={{ base: "16", md: "24" }}>
      <Stack spacing={{ base: "12", md: "16" }} alignItems="stretch">
        <Stack spacing={{ base: "4", md: "5" }} direction="column">
          <Heading size={{ base: "2xl", md: "2xl" }}>{name}Finance</Heading>
          <Text color="fg.muted" textStyle={{ base: "lg", md: "xl" }}>
            Our impact by the numbers
          </Text>
        </Stack>
        <Stack spacing="8" direction={{ base: "column", md: "row" }}>
          {stats.map((stat) => (
            <Stat key={stat.label} {...stat} />
          ))}
        </Stack>
      </Stack>
    </Container>
  );
};
